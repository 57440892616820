var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('v-app-bar',{attrs:{"app":"","color":"white","flat":""}},[(_vm.$vuetify.breakpoint.xs? true: false)?_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}):_vm._e(),_c('v-container',{staticClass:"py-0 fill-height page-container"},[_c('v-img',{staticClass:"mr-10",attrs:{"max-height":"50","max-width":"150","src":require('@/assets/logo.png')}}),_vm._l((_vm.linksByUser),function(link){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.xs? false: true),expression:"$vuetify.breakpoint.xs? false: true"}],key:link.label},[(!link.children)?_c('v-btn',{attrs:{"to":link.path,"href":link.link,"target":link.link ? '_blank' : '',"text":""}},[_vm._v(" "+_vm._s(link.label)+" ")]):_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(link.label)+" ")])]}}],null,true)},[_c('v-list',_vm._l((link.children),function(linkChildren,index){return _c('v-list-item',{key:index,attrs:{"to":linkChildren.path}},[_c('v-list-item-title',[_vm._v(_vm._s(linkChildren.label))])],1)}),1)],1)],1)}),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"color":"primary","size":"32"}},'v-avatar',attrs,false),on),[_c('span',{staticClass:"white--text text-h7"},[_vm._v(_vm._s(_vm.avatarText))])])]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":_vm.logOut}},[_c('v-list-item-title',[_vm._v("Sair")])],1)],1)],1)],2)],1),(_vm.drawer)?_c('v-navigation-drawer',{attrs:{"app":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',_vm._l((_vm.linksByUser),function(link){return _c('div',{key:link.label},[_c('v-list-item',[(!link.children)?_c('v-btn',{attrs:{"to":link.path,"href":link.link,"target":link.link ? '_blank' : '',"text":""}},[_vm._v(" "+_vm._s(link.label)+" ")]):_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(link.label)+" ")])]}}],null,true)},[_c('v-list',_vm._l((link.children),function(linkChildren,index){return _c('v-list-item',{key:index,attrs:{"to":linkChildren.path}},[_c('v-list-item-title',[_vm._v(_vm._s(linkChildren.label))])],1)}),1)],1)],1)],1)}),0)],1):_vm._e(),_c('v-main',{staticClass:"grey lighten-3"},[_c('v-container',{staticClass:"page-container"},[_c('vue-page-transition',{attrs:{"name":"fade-in-right"}},[_c('router-view')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }