<script>
import { getAvatarText } from '@/utils/text.js'
import cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'

function verifyRole(role, link) {
  if (!link.onlyRoles) return link
  if(link.onlyRoles.includes(role)) {
    if (link.children) {
      link.children = link.children
        .map(item => verifyRole(role, item))
        .filter(item => !!item)
    }
    return link
  }
  else return null
}

export default {
  data() {
    return {
      drawer: false,
      links: [
        { label: 'Painel', path: '/', onlyRoles: ['manager', 'consultant', 'customer'] },
        { label: 'Perfil', path: '/perfil', onlyRoles: ['manager', 'consultant', 'customer'] },
        { label: 'Imagens', path: '/satelite', onlyRoles: ['manager', 'consultant', 'customer'] },
        { label: 'Mapa', path: '/map', onlyRoles: ['manager','consultant', 'customer'] },
        { label: 'Calculadora', path: '/calculadora', onlyRoles: ['manager', 'consultant', 'customer'] },
        { label: 'Lotes', path: '/lotes', onlyRoles: ['manager', 'consultant', 'customer'] },
        {
          label: 'Administrativo',
          onlyRoles: ['manager', 'consultant'],
          children: [
            { label: 'Clientes', path: '/clientes', onlyRoles: ['manager', 'consultant'] },
            { label: 'Fazendas', path: '/fazendas', onlyRoles: ['manager', 'consultant'] },
            { label: 'Usuários', path: '/usuarios', onlyRoles: ['manager'] },
            { label: 'Espécies e cultivares', path: '/cultivares', onlyRoles: ['manager', 'consultant'] },
            { label: 'Ajuste de medição', path: '/medicoes', onlyRoles: ['manager'] },
            { label: 'Imagens', path: '/imagens', onlyRoles: ['manager'] },
            { label: 'Parâmetros', path: '/parametros', onlyRoles: ['manager'] },
            { label: 'Fatores de ajuste', path: '/fatores', onlyRoles: ['manager','consultant'] },
          ],
        },
        { label: 'Sobre nós', link: 'https://consultoriapecuaria.com.br/quem-somos/' },
      ],
    }
  },

  computed: {
    ...mapGetters('auth', ['authStatus', 'user']),
    avatarText() {
      return getAvatarText(this.user?.name)
    },

    linksByUser() {
      const role = this.user.role
      const links = cloneDeep(this.links)
      return links
        .map(item => verifyRole(role, item))
        .filter(item => !!item)
    }
  },

  methods: {
    logOut() {
      this.$store.dispatch('auth/logOut')
        .then(() => this.$router.push('/autenticacao'))
    },
  }

};
</script>

<template>
  <v-app id="inspire">
    <v-app-bar app color="white" flat>
      <v-app-bar-nav-icon v-if="$vuetify.breakpoint.xs? true: false" @click.stop="drawer = !drawer">
      </v-app-bar-nav-icon>
      <v-container class="py-0 fill-height page-container">
        <v-img class="mr-10" max-height="50" max-width="150" :src="require('@/assets/logo.png')"></v-img>
        <div v-for="link in linksByUser" v-show="$vuetify.breakpoint.xs? false: true" :key="link.label">
          <v-btn v-if="!link.children" :to="link.path" :href="link.link" :target="link.link ? '_blank' : ''" text>
            {{ link.label }}
          </v-btn>
          <v-menu v-else offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text v-bind="attrs" v-on="on">
                {{ link.label }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(linkChildren, index) in link.children" :key="index" :to="linkChildren.path">
                <v-list-item-title>{{ linkChildren.label }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <v-spacer />

        <v-menu offset-y left>
          <template v-slot:activator="{ on, attrs }">
            <v-avatar color="primary" size="32" v-bind="attrs" v-on="on">
              <span class="white--text text-h7">{{ avatarText }}</span>
            </v-avatar>
          </template>
          <v-list>
            <v-list-item @click="logOut">
              <v-list-item-title>Sair</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-container>
    </v-app-bar>
    <v-navigation-drawer
      v-if="drawer"
      v-model="drawer"
      app
      temporary
    >
      <v-list>
        <div v-for="link in linksByUser" :key="link.label">
          <v-list-item>
            <v-btn v-if="!link.children" :to="link.path" :href="link.link" :target="link.link ? '_blank' : ''" text>
              {{ link.label }}
            </v-btn>
            <v-menu v-else offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text v-bind="attrs" v-on="on">
                  {{ link.label }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(linkChildren, index) in link.children" :key="index" :to="linkChildren.path">
                  <v-list-item-title>{{ linkChildren.label }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item>
        </div>
      </v-list>
    </v-navigation-drawer>
    <v-main class="grey lighten-3">
      <v-container class="page-container">
        <vue-page-transition name="fade-in-right">
          <router-view />
        </vue-page-transition>
      </v-container>
    </v-main>
  </v-app>
</template>
